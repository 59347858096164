<template>
  <div class="header">

    <!--ppt--图01 效果1-->
    <el-image
      :src="require('../assets/common/logo1@2x.png')"
      fit="contain"
      style="
        width: 3.22rem;
        height: auto;
        margin-left: -0.3rem;
        margin-right: 0.8rem;
      "

    />
    <!--2022.7.13 logo更换-->
    <!--<el-image
      :src="require('../assets/common/logo4.jpg')"
      fit="contain"
      style="
          width: 3.0rem;
          height: 0.72rem;
          margin-top: 0.075rem;
          margin-left: -0.3rem;
          margin-right: 0.8rem;
      "

    />-->


    <!--  ZBM： vue动态绑定css   多个用逗号隔开

     :class="{'active':true}"

     :class="{'active':isActive==-1}"

    -->


    <!--
     <div
       v-for="(item, index) in dockerList"
       :class="{'header__item': true, 'header__item--active': index === currentIndex}"
       :key="item.index">
       <router-link :to='item.to'>
         <div class="header__title">{{item.text}}</div>
       </router-link>
     </div>
    -->

   <!--  change，2021.8.10，模拟龙新净水，鼠标放置变色、弹出下拉框  -->


    <!--首页-->
    <router-link :to='dockerList[0].to'
       class="header__item"
       :class="{'header__item--active': 0 === tabIndex || currentIndex==0}"
       @mouseover="() => handleTabMouseOver(0)"
       @mouseleave="() => handleTabMouseOver(-1)"
    >
      <div class="header__title">{{dockerList[0].text}}</div>
    </router-link>

    <!--产品中心-->
    <router-link :to='dockerList[1].to'
       class="header__item"
       :class="{'header__item--active': 1 === tabIndex || currentIndex===1}"
       @mouseover="() => handleTabMouseOver(1)"
       @mouseleave="() => handleTabMouseOver(-1)"
    >
      <div class="header__title">{{dockerList[1].text}}</div>

      <!--弹出窗1  4个0.4rem-->
      <view v-if=" 1 === tabIndex  && isLeaved ==false"
        style="
          width: 100%;
          height: 1.6rem;
          background: white;
          position: absolute;
          left: 0;
          top: 0.84rem;

          display: flex;
          flex-direction: column;
          align-items: center;
        ">

        <!-- tag1.1  -->
        <text
          :class="{'subItem':true, 'activeItem':tagNum=='1.1'}"
          @click="() => handleTabClickItem('1.1')"
          @mouseover="() => handleTabMouseOverItem('1.1')"
          @mouseleave="() => handleTabMouseOverItem('0')"
        >
          智能一体化设备
        </text>

        <!-- tag1.2  -->
        <text
          :class="{'subItem':true, 'activeItem':tagNum=='1.2'}"
          @click="() => handleTabClickItem('1.2')"
          @mouseover="() => handleTabMouseOverItem('1.2')"
          @mouseleave="() => handleTabMouseOverItem('0')"
        >
          设备组件
        </text>

        <!-- tag1.3  -->
        <text
          :class="{'subItem':true, 'activeItem':tagNum=='1.3'}"
          @click="() => handleTabClickItem('1.3')"
          @mouseover="() => handleTabMouseOverItem('1.3')"
          @mouseleave="() => handleTabMouseOverItem('0')"
        >
          饮水终端
        </text>

        <!-- tag1.4  -->
        <text
          :class="{'subItem':true, 'activeItem':tagNum=='1.4'}"
          @click="() => handleTabClickItem('1.4')"
          @mouseover="() => handleTabMouseOverItem('1.4')"
          @mouseleave="() => handleTabMouseOverItem('0')"
        >
          直饮水机
        </text>


      </view>


    </router-link>

    <!--解决方案-->
    <router-link :to='dockerList[2].to'
       class="header__item"
       :class="{'header__item--active': 2 === tabIndex || currentIndex===2}"
       @mouseover="() => handleTabMouseOver(2)"
       @mouseleave="() => handleTabMouseOver(-1)"
    >
      <div class="header__title">{{dockerList[2].text}}</div>

      <!--弹出窗2  3个0.4rem-->
      <view v-if=" 2 === tabIndex  && isLeaved ==false"
            style="
          width: 100%;
          height: 1.2rem;
          background: white;
          position: absolute;
          left: 0;
          top: 0.84rem;

          display: flex;
          flex-direction: column;
          align-items: center;
        ">

        <!-- tag2.1  -->
        <text
          :class="{'subItem':true, 'activeItem':tagNum=='2.1'}"
          @click="() => handleTabClickItem('2.1')"
          @mouseover="() => handleTabMouseOverItem('2.1')"
          @mouseleave="() => handleTabMouseOverItem('0')"
        >
          社区住宅
        </text>

        <!-- tag2.2  -->
        <text
          :class="{'subItem':true, 'activeItem':tagNum=='2.2'}"
          @click="() => handleTabClickItem('2.2')"
          @mouseover="() => handleTabMouseOverItem('2.2')"
          @mouseleave="() => handleTabMouseOverItem('0')"
        >
          商业办公
        </text>

        <!-- tag2.3  -->
        <text
          :class="{'subItem':true, 'activeItem':tagNum=='2.3'}"
          @click="() => handleTabClickItem('2.3')"
          @mouseover="() => handleTabMouseOverItem('2.3')"
          @mouseleave="() => handleTabMouseOverItem('0')"
        >
          机关单位
        </text>



      </view>

    </router-link>

    <!--案例中心-->
    <router-link :to='dockerList[3].to'
       class="header__item"
       :class="{'header__item--active': 3 === tabIndex || currentIndex===3}"
       @mouseover="() => handleTabMouseOver(3)"
       @mouseleave="() => handleTabMouseOver(-1)"
    >
      <div class="header__title">{{dockerList[3].text}}</div>
    </router-link>

    <!--创美优势 add 2022.7.28-->
    <router-link :to='dockerList[4].to'
                 class="header__item"
                 :class="{'header__item--active': 4 === tabIndex || currentIndex===4}"
                 @mouseover="() => handleTabMouseOver(4)"
                 @mouseleave="() => handleTabMouseOver(-1)"
    >
      <div class="header__title">{{dockerList[4].text}}</div>
    </router-link>

    <!--新闻资讯-->
    <router-link :to='dockerList[5].to'
       class="header__item"
       :class="{'header__item--active': 5 === tabIndex || currentIndex===5}"
       @mouseover="() => handleTabMouseOver(5)"
       @mouseleave="() => handleTabMouseOver(-1)"
    >
      <div class="header__title">{{dockerList[5].text}}</div>

      <!--弹出窗3  2个0.4rem-->
      <view v-if=" 5 === tabIndex  && isLeaved ==false"
            style="
          width: 100%;
          height: 0.8rem;
          background: white;
          position: absolute;
          left: 0;
          top: 0.84rem;

          display: flex;
          flex-direction: column;
          align-items: center;
        ">

        <!-- tag5.1  -->
        <text
          :class="{'subItem':true, 'activeItem':tagNum=='5.1'}"
          @click="() => handleTabClickItem('5.1')"
          @mouseover="() => handleTabMouseOverItem('5.1')"
          @mouseleave="() => handleTabMouseOverItem('0')"
        >
          行业新闻
        </text>

        <!-- tag5.2  -->
        <text
          :class="{'subItem':true, 'activeItem':tagNum=='5.2'}"
          @click="() => handleTabClickItem('5.2')"
          @mouseover="() => handleTabMouseOverItem('5.2')"
          @mouseleave="() => handleTabMouseOverItem('0')"
        >
          产品资讯
        </text>



      </view>


    </router-link>

    <!--关于我们-->
    <router-link :to='dockerList[6].to'
       class="header__item"
       :class="{'header__item--active': 6 === tabIndex || currentIndex===6}"
       @mouseover="() => handleTabMouseOver(6)"
       @mouseleave="() => handleTabMouseOver(-1)"
    >
      <div class="header__title">{{dockerList[6].text}}</div>

      <!--弹出窗4  5个0.4rem-->
      <view v-if=" 6 === tabIndex  && isLeaved ==false"
            style="
          width: 100%;
          height: 2.0rem;
          background: white;
          position: absolute;
          left: 0;
          top: 0.84rem;

          display: flex;
          flex-direction: column;
          align-items: center;
        ">

        <!-- tag6.1  -->
        <text
          :class="{'subItem':true, 'activeItem':tagNum=='6.1'}"
          @click="() => handleTabClickItem('6.1')"
          @mouseover="() => handleTabMouseOverItem('6.1')"
          @mouseleave="() => handleTabMouseOverItem('0')"
        >
          公司简介
        </text>

        <!-- tag6.2  -->
        <text
          :class="{'subItem':true, 'activeItem':tagNum=='6.2'}"
          @click="() => handleTabClickItem('6.2')"
          @mouseover="() => handleTabMouseOverItem('6.2')"
          @mouseleave="() => handleTabMouseOverItem('0')"
        >
          发展历程
        </text>

        <!-- tag6.3  -->
        <text
          :class="{'subItem':true, 'activeItem':tagNum=='6.3'}"
          @click="() => handleTabClickItem('6.3')"
          @mouseover="() => handleTabMouseOverItem('6.3')"
          @mouseleave="() => handleTabMouseOverItem('0')"
        >
          资质荣誉
        </text>

        <!-- tag6.4  -->
        <text
          :class="{'subItem':true, 'activeItem':tagNum=='6.4'}"
          @click="() => handleTabClickItem('6.4')"
          @mouseover="() => handleTabMouseOverItem('6.4')"
          @mouseleave="() => handleTabMouseOverItem('0')"
        >
          企业文化
        </text>

        <!-- tag6.5  -->
        <text v-show="false"
          :class="{'subItem':true, 'activeItem':tagNum=='6.5'}"
          @click="() => handleTabClickItem('6.5')"
          @mouseover="() => handleTabMouseOverItem('6.5')"
          @mouseleave="() => handleTabMouseOverItem('0')"
        >
          加入创美
        </text>

        <!-- tag6.6  -->
        <text
          :class="{'subItem':true, 'activeItem':tagNum=='6.6'}"
          @click="() => handleTabClickItem('6.6')"
          @mouseover="() => handleTabMouseOverItem('6.6')"
          @mouseleave="() => handleTabMouseOverItem('0')"
        >
          联系我们
        </text>


      </view>

    </router-link>


    <div class="header__phoneView">
      <img class="header__phoneView_pic"  src="../assets/common/top_pone@2x.png"/>
      <text class="header__phoneView_txt"> 0311-88802333</text>
    </div>

  </div>
</template>



<script>
    import {ref} from "vue";
    import {useRouter} from "vue-router";
    import {useStore} from "vuex";

    //跨组件，事件传递
    import Bus from "../service/bus";


    export default {
      name: "Header",
      props: ['currentIndex'],

      setup(props) {

        const router = useRouter()
        const store = useStore()

        const dockerList = [
          {text: '首页', to: {name: 'Home'}},
          {text: '产品中心', to: {name: 'Products'}},
          {text: '解决方案', to: {name: 'Solutions'}},
          {text: '案例中心', to: {name: 'ProjectCases'}},
          {text: '创美优势', to: {name: 'Advantages'}},//add 2022.7.28
          {text: '新闻资讯', to: {name: 'News'}},
          {text: '关于我们', to: {name: 'AboutUs'}},
        ];


        //tab
        const tabIndex = ref(-1)//0.首页 1.产品中心...
        // if(tabIndex.value ===0)
        // {
        //   if(props.currentIndex !== 0)
        //   {
        //     tabIndex.value = props.currentIndex;
        //   }
        // }

        //add 2021.9.1 顶部导航
        const isLeaved = ref(false);

        //tab鼠标上移函数定义
        const handleTabMouseOver = (index) => {
          //console.log("handleTabMouseOver=======> "+index);

          if(index === -1) {
            //console.log("index === -1,  props.currentIndex=======> "+props.currentIndex);
            tabIndex.value = props.currentIndex; //todo：setup中使用父组件传递过来的props

            //add 2021.9.1 顶部导航
            isLeaved.value = true;
            return;
          }

          tabIndex.value = index;

          //add 2021.9.1 顶部导航
          isLeaved.value = false;
        }



        /*顶部导航*/
        const tagNum = ref('0')//tag编号

        /*跨组件事件发送*/
        const sendEvent= (eventname, data) =>{
          Bus.$emit(eventname, data);
        }

        //tab点击函数定义
        const handleTabClickItem = (tagNumber) => {
          tagNum.value = tagNumber;
          console.log("handleTabClickItem=======》 " + tagNumber)

          /*跳转到不同的页面*/
          //产品中心
          //1.1 智慧管道直饮水系统
          if (tagNumber === '1.1') {
            let discard = false;
            let index = 1;
            store.commit('changeProductsRoute', {discard, index});//更新数据

            router.push(`/products`);
            sendEvent("changeProductsRoute", 1);
          } else if (tagNumber === '1.2') {
            let discard = false;
            let index = 2;
            store.commit('changeProductsRoute', {discard, index});//更新数据

            router.push(`/products`);
            sendEvent("changeProductsRoute", 2);
          } else if (tagNumber === '1.3') {
            let discard = false;
            let index = 3;
            store.commit('changeProductsRoute', {discard, index});//更新数据

            router.push(`/products`);
            sendEvent("changeProductsRoute", 3);
          } else if (tagNumber === '1.4') {
            let discard = false;
            let index = 4;
            store.commit('changeProductsRoute', {discard, index});//更新数据

            router.push(`/products`)
            sendEvent("changeProductsRoute", 4);
          }
            //解决方案
          //2.1 智慧管道直饮水系统
          else if (tagNumber === '2.1') {
            let discard = false;
            let index = 1;
            store.commit('changeSolutionsRoute', {discard, index});//更新数据

            router.push(`/solutions`)
            sendEvent("changeSolutionsRoute", 1);
          } else if (tagNumber === '2.2') {
            let discard = false;
            let index = 2;
            store.commit('changeSolutionsRoute', {discard, index});//更新数据

            router.push(`/solutions`)
            sendEvent("changeSolutionsRoute", 2);
          } else if (tagNumber === '2.3') {
            let discard = false;
            let index = 3;
            store.commit('changeSolutionsRoute', {discard, index});//更新数据

            router.push(`/solutions`)
            sendEvent("changeSolutionsRoute", 3);
          }
            //案例中心
          //3.1
          else if(tagNumber === '3.1'){
            // let discard = false;
            // let index =1;
            // store.commit('changeNewsRouteRoute', {discard, index });//更新数据

            router.push(`/projectCases`)
            // sendEvent("changeNewsRouteRoute", 1);
          }
            //add 2022.7.28
            //创美优势
          //4.1
          else if(tagNumber === '4.1'){
            // let discard = false;
            // let index =1;
            // store.commit('changeNewsRouteRoute', {discard, index });//更新数据

            router.push(`/advantages`)
            // sendEvent("changeNewsRouteRoute", 1);
          }

            //新闻资讯
          //5.1
          else if(tagNumber === '5.1'){
            let discard = false;
            let index =1;
            store.commit('changeNewsRouteRoute', {discard, index });//更新数据

            router.push(`/news`)
            sendEvent("changeNewsRouteRoute", 1);
          }
          //5.2
          else if(tagNumber === '5.2'){
            let discard = false;
            let index =2;
            store.commit('changeNewsRouteRoute', {discard, index });//更新数据

            router.push(`/news`)
            sendEvent("changeNewsRouteRoute", 2);
          }

            //关于我们
          //6.1
          else if(tagNumber === '6.1'){
            let discard = false;
            let index =1;
            store.commit('changeAboutusRoute', {discard, index });//更新数据

            router.push(`/aboutUs`)
            sendEvent("changeAboutusRoute", 1);
          }
          //6.2
          else if(tagNumber === '6.2'){
            let discard = false;
            let index =2;
            store.commit('changeAboutusRoute', {discard, index });//更新数据

            router.push(`/aboutUs`)
            sendEvent("changeAboutusRoute", 2);
          }
          //6.3
          else if(tagNumber === '6.3'){
            let discard = false;
            let index =3;
            store.commit('changeAboutusRoute', {discard, index });//更新数据

            router.push(`/aboutUs`)
            sendEvent("changeAboutusRoute", 3);
          }
          //6.4
          else if(tagNumber === '6.4'){
            let discard = false;
            let index =4;
            store.commit('changeAboutusRoute', {discard, index });//更新数据

            router.push(`/aboutUs`)
            sendEvent("changeAboutusRoute", 4);
          }
          //6.5
          else if(tagNumber === '6.5'){
            let discard = false;
            let index =5;
            store.commit('changeAboutusRoute', {discard, index });//更新数据

            router.push(`/aboutUs`)
            sendEvent("changeAboutusRoute", 5);
          }
          //6.6
          else if(tagNumber === '6.6'){
            let discard = false;
            let index =6;
            store.commit('changeAboutusRoute', {discard, index });//更新数据

            router.push(`/aboutUs`)
            sendEvent("changeAboutusRoute", 6);
          }

        }

        //tab鼠标上移函数定义
        const handleTabMouseOverItem = (tagNumber) => {
          tagNum.value = tagNumber;
        }




        return { dockerList, tabIndex, handleTabMouseOver, isLeaved,handleTabClickItem,handleTabMouseOverItem,tagNum}

      }


    }
</script>



<style lang="scss" scoped>
  @import '../style/viriables.scss';

  .header {
    display: flex;
    flex-direction: row;
    box-sizing: border-box; /*带边框*/
    position: relative;
    /*left: 0;*/
    /*top:0;*/
    width: 100%;
    height: 0.82rem;
    background-color: white;
    opacity: 0.9;
    margin-bottom: 0.022rem;

    z-index: 2;   /*add 2021.8.28 为了顶部的下拉框能显示出来*/

    &__logoPic{
      width: 3.22rem;
      height: 0.44rem;
      margin-left: 0.6rem;
      margin-top: 0.20rem;
      margin-right: 1.08rem;
    }

    &__item {
      display: flex;
      justify-content: center;
      align-items: center;

      height: 0.82rem;
      width: 1.17rem;
      opacity: 0.6;

      position: relative;/*add 2021.8.28 增加顶部下拉框*/

      /*  change 2021.8.10，不生效了，去掉
      a {
        color: $dark-fontColor;
        text-decoration: none;
      }
      */
      text-decoration: none;
      color: $dark-fontColor;

      &--active {
        border-bottom: .021rem solid $colorPrimary;
        opacity: 1;

        /*  change 2021.8.10，不生效了，去掉
          a {
            color: $colorPrimary;
            font-weight: bold;
          }
        */
        color: $colorPrimary;
        font-weight: bold;

    }

  }

  &__title {
    font-family: Source Han Sans SC;
    font-size: .2rem;
    /*opacity: 0.6;*/
      /*transform: scale(.5, .5);*/
      /*transform-origin: center top;*/
    }

    &__phoneView{
      width: auto;

      height:0.82rem;
      /*line-height:0.82rem;*/
      /*overflow:hidden;*/
      position: absolute;
      right: 2.41rem;
      display: flex;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: center;

      &_pic{
        width: 0.3rem;
        height: 0.3rem;
      }

      &_txt{
        width: auto;
        height: auto;
        font-size: 0.2rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        /*line-height: 160px;*/

        margin-left: 0.09rem;
      }



    }


  }

  .subItem{
    width: 100%;
    height: 0.4rem;
    line-height: 0.4rem;
    font-size: 0.15rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: black;
    text-align: center;
  }

  /*选中设备类型后的，按钮激活效果*/
  .activeItem{
    background:  $colorPrimary;
    color: white;
  }


</style>

