<template>

  <!--  v-lazy:background-image="require('../assets/common/home_bg@2x.jpg')" -->
  <div class="rootView"   :class="{'marginOff':topMargin!==undefined && topMargin==0}">

     <!--背景图，add2021.8.10,改为懒加载-->
     <!-- <el-image
        :src="require('../assets/common/home_bg@2x.jpg')"
        fit="cover"
        style="
          width: 100%;
          height: 4rem;
          position:absolute;
        "
        lazy
      />
    -->

    <!--  二维码区域    -->
    <el-image
      :src="require('../assets/common/wx_code.png')"
      fit="cover"
      style="
        width: 1.50rem;
        height: 1.42rem;

        position:absolute;
        top: 0.40rem;
        left: 1.48rem;
      "
      lazy
    />

    <view class="rootView_left">
      <text
        style="
          width: auto;
          height: auto;
          font-size: 0.13rem;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 0.21rem;

          margin-top: 0rem;
          margin-left: 0rem;

          ">
        公司地址：石家庄高新区珠江大道313号方亿科技园C区3号楼3单元301
      </text>

      <text
        style="
          width: auto;
          height: auto;
          font-size: 0.13rem;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 0.21rem;

          margin-top: 0.01rem;
          margin-left: 0rem;

          ">
        服务电话：0311-88802333
      </text>

      <text
        style="
          width: auto;
          height: auto;
          font-size: 0.13rem;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 0.21rem;

          margin-top: 0.01rem;
          margin-left: 0rem;

        ">
        公司邮箱：youxiang@chuangmei.com.cn
      </text>



    </view>

    <view class="rootView_left_bottom">
      <text
        style="
            width: auto;
            height: 0.12rem;

            font-size: 0.14rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #FFFFFF;

        ">
        ©2021河北创美科技有限公司
      </text>

      <text
        style="
            width: auto;
            height: 0.12rem;

            font-size: 0.14rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #FFFFFF;


            margin-top: 0rem;
            margin-left: 0.1rem;

        ">
        网站许可证号：
      </text>

      <el-link
        href="https://beian.miit.gov.cn/"
        target="_blank"

        style="
            width: auto;
            height: 0.15rem;

            font-size: 0.14rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #FFFFFF;

            margin-top: 0.00rem;
        ">
        冀ICP备2021012266号
      </el-link>

    </view>

    <view class="rootView_left_bottom2">
      <el-image
        :src="require('../assets/common/beian.png')"
        fit="contain"
        style="
        width: 0.15rem;
        height: 0.15rem;
      "
         lazy
      />

      <el-link
        href="http://www.beian.gov.cn/"
        target="_blank"

        style="
            width: auto;
            height: 0.12rem;

            font-size: 0.14rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #FFFFFF;

            margin-top: 0.02rem;
        ">
        冀公网安备 13019902000406号
      </el-link>

    </view>


    <!-- 中间区域   -->
    <view class="rootView_center">

      <!-- 产品中心  -->
      <view class="rootView_center_item">
        <!-- 标题  -->
        <text class="rootView_center_item_title">
          产品中心
        </text>

        <!-- tag1.1  -->
        <text
          :class="{'rootView_center_item_tag':true, 'active':tagNum=='1.1'}"
          @click="() => handleTabClick('1.1')"
          @mouseover="() => handleTabMouseOver('1.1')"
          @mouseleave="() => handleTabMouseOver('0')"
        >
          智能一体化设备
        </text>

        <!-- tag1.2  -->
        <text
          :class="{'rootView_center_item_tag':true, 'active':tagNum=='1.2'}"
          @click="() => handleTabClick('1.2')"
          @mouseover="() => handleTabMouseOver('1.2')"
          @mouseleave="() => handleTabMouseOver('0')"
        >
          设备组件
        </text>

        <!-- tag1.3  -->
        <text
          :class="{'rootView_center_item_tag':true, 'active':tagNum=='1.3'}"
          @click="() => handleTabClick('1.3')"
          @mouseover="() => handleTabMouseOver('1.3')"
          @mouseleave="() => handleTabMouseOver('0')"
        >
          饮水终端
        </text>

        <!-- tag1.4  -->
        <text
          :class="{'rootView_center_item_tag':true, 'active':tagNum=='1.4'}"
          @click="() => handleTabClick('1.4')"
          @mouseover="() => handleTabMouseOver('1.4')"
          @mouseleave="() => handleTabMouseOver('0')"
        >
          直饮水机
        </text>


      </view>

      <!-- 解决方案  -->
      <view class="rootView_center_item">
        <!-- 标题  -->
        <text class="rootView_center_item_title">
          解决方案
        </text>

        <!-- tag2.1  -->
        <text
          :class="{'rootView_center_item_tag':true, 'active':tagNum=='2.1'}"
          @click="() => handleTabClick('2.1')"
          @mouseover="() => handleTabMouseOver('2.1')"
          @mouseleave="() => handleTabMouseOver('0')"
        >
          社区住宅
        </text>

        <!-- tag2.2  -->
        <text
          :class="{'rootView_center_item_tag':true, 'active':tagNum=='2.2'}"
          @click="() => handleTabClick('2.2')"
          @mouseover="() => handleTabMouseOver('2.2')"
          @mouseleave="() => handleTabMouseOver('0')"
        >
          商业办公
        </text>

        <!-- tag2.3  -->
        <text
          :class="{'rootView_center_item_tag':true, 'active':tagNum=='2.3'}"
          @click="() => handleTabClick('2.3')"
          @mouseover="() => handleTabMouseOver('2.3')"
          @mouseleave="() => handleTabMouseOver('0')"
        >
          机关单位
        </text>


      </view>

      <!-- 案例中心  -->
      <view class="rootView_center_item">
        <!-- 标题  -->
        <text class="rootView_center_item_title">
          案例中心
        </text>

        <!-- tag3.1  -->
        <text
          :class="{'rootView_center_item_tag':true, 'active':tagNum=='3.1'}"
          @click="() => handleTabClick('3.1')"
          @mouseover="() => handleTabMouseOver('3.1')"
          @mouseleave="() => handleTabMouseOver('0')"
        >
          案例中心
        </text>


      </view>

      <!-- 创美优势  add 2022.7.28  -->
      <view class="rootView_center_item">
        <!-- 标题  -->
        <text class="rootView_center_item_title">
          创美优势
        </text>

        <!-- tag4.1  -->
        <text
          :class="{'rootView_center_item_tag':true, 'active':tagNum=='4.1'}"
          @click="() => handleTabClick('4.1')"
          @mouseover="() => handleTabMouseOver('4.1')"
          @mouseleave="() => handleTabMouseOver('0')"
        >
          创美优势
        </text>


      </view>

      <!-- 新闻资讯  -->
      <view class="rootView_center_item">
        <!-- 标题  -->
        <text class="rootView_center_item_title">
          新闻资讯
        </text>

        <!-- tag5.1  -->
        <text
          :class="{'rootView_center_item_tag':true, 'active':tagNum=='5.1'}"
          @click="() => handleTabClick('5.1')"
          @mouseover="() => handleTabMouseOver('5.1')"
          @mouseleave="() => handleTabMouseOver('0')"
        >
          行业新闻
        </text>

        <!-- tag5.2  -->
        <text
          :class="{'rootView_center_item_tag':true, 'active':tagNum=='5.2'}"
          @click="() => handleTabClick('5.2')"
          @mouseover="() => handleTabMouseOver('5.2')"
          @mouseleave="() => handleTabMouseOver('0')"
        >
          产品资讯
        </text>

      </view>

      <!-- 关于我们  -->
      <view class="rootView_center_item">
        <!-- 标题  -->
        <text class="rootView_center_item_title">
          关于我们
        </text>

        <!-- tag6.1  -->
        <text
          :class="{'rootView_center_item_tag':true, 'active':tagNum=='6.1'}"
          @click="() => handleTabClick('6.1')"
          @mouseover="() => handleTabMouseOver('6.1')"
          @mouseleave="() => handleTabMouseOver('0')"
        >
          公司简介
        </text>

        <!-- tag6.2  -->
        <text
          :class="{'rootView_center_item_tag':true, 'active':tagNum=='6.2'}"
          @click="() => handleTabClick('6.2')"
          @mouseover="() => handleTabMouseOver('6.2')"
          @mouseleave="() => handleTabMouseOver('0')"
        >
          发展历程
        </text>

        <!-- tag6.3  -->
        <text
          :class="{'rootView_center_item_tag':true, 'active':tagNum=='6.3'}"
          @click="() => handleTabClick('6.3')"
          @mouseover="() => handleTabMouseOver('6.3')"
          @mouseleave="() => handleTabMouseOver('0')"
        >
          资质荣誉
        </text>

        <!-- tag6.4  -->
        <text
          :class="{'rootView_center_item_tag':true, 'active':tagNum=='6.4'}"
          @click="() => handleTabClick('6.4')"
          @mouseover="() => handleTabMouseOver('6.4')"
          @mouseleave="() => handleTabMouseOver('0')"
        >
          企业文化
        </text>

        <!-- tag5.5  -->
        <text v-show="false"
          :class="{'rootView_center_item_tag':true, 'active':tagNum=='6.5'}"
          @click="() => handleTabClick('6.5')"
          @mouseover="() => handleTabMouseOver('6.5')"
          @mouseleave="() => handleTabMouseOver('0')"
        >
          加入创美
        </text>

        <!-- tag5.6  -->
        <text
          :class="{'rootView_center_item_tag':true, 'active':tagNum=='6.6'}"
          @click="() => handleTabClick('6.6')"
          @mouseover="() => handleTabMouseOver('6.6')"
          @mouseleave="() => handleTabMouseOver('0')"
        >
          联系我们
        </text>


      </view>


    </view>

    <!-- 联系客服 图标  -->
    <el-image
      :src="require('../assets/common/btn_service@2x.png')"
      fit="cover"
      @click="showMsgDialog"
      style="
        width: 0.8rem;
        height: 0.8rem;

        position:absolute;
        top: 0.20rem;
        right: 0.80rem;
      "
    />

    <!-- 回调顶部 图标   -->
    <el-image
      :src="require('../assets/common/btn_top@2x.png')"
      fit="cover"
      @click="backTopClick"
      style="
        width: 0.8rem;
        height: 0.8rem;

        position:absolute;
        top: 1.20rem;
        right: 0.80rem;
      "
    />






  </div>
</template>

<script>
  import { useRouter } from 'vue-router'// CompositionAPI 中使用 Vue-Router
  import { ref } from 'vue'
  import { useContext } from 'vue'
  import { useStore } from 'vuex'
  import Bus from "../service/bus";
  // CompositionAPI 中使用 VueX

  export default {
    name: "Footer",
    props: ['topMargin'],

    setup() {

      const router = useRouter()
      const store = useStore()
      const tagNum = ref('0')//tag编号

      /*跨组件事件发送*/
      const sendEvent= (eventname, data) =>{
        Bus.$emit(eventname, data);
      }

      //tab点击函数定义
      const handleTabClick = (tagNumber) => {
        tagNum.value = tagNumber;
        console.log("handleTabClick=======》 "+tagNumber)

        /*跳转到不同的页面*/
        //产品中心
        //1.1 智慧管道直饮水系统
        if(tagNumber === '1.1'){
          let discard = false;
          let index =1;
          store.commit('changeProductsRoute', {discard, index });//更新数据

          router.push(`/products`)
          sendEvent("changeProductsRoute", 1);
        }
        else if(tagNumber === '1.2'){
          let discard = false;
          let index =2;
          store.commit('changeProductsRoute', {discard, index });//更新数据

          router.push(`/products`)
          sendEvent("changeProductsRoute", 2);
        }
        else if(tagNumber === '1.3'){
          let discard = false;
          let index =3;
          store.commit('changeProductsRoute', {discard, index });//更新数据

          router.push(`/products`)
          sendEvent("changeProductsRoute", 3);
        }
        else if(tagNumber === '1.4'){
          let discard = false;
          let index =4;
          store.commit('changeProductsRoute', {discard, index });//更新数据

          router.push(`/products`)
          sendEvent("changeProductsRoute", 4);
        }
        //解决方案
        //2.1 智慧管道直饮水系统
        else if(tagNumber === '2.1'){
          let discard = false;
          let index =1;
          store.commit('changeSolutionsRoute', {discard, index });//更新数据

          router.push(`/solutions`)
          sendEvent("changeSolutionsRoute", 1);
        }
        else if(tagNumber === '2.2'){
          let discard = false;
          let index =2;
          store.commit('changeSolutionsRoute', {discard, index });//更新数据

          router.push(`/solutions`)
          sendEvent("changeSolutionsRoute", 2);
        }
        else if(tagNumber === '2.3'){
          let discard = false;
          let index =3;
          store.commit('changeSolutionsRoute', {discard, index });//更新数据

          router.push(`/solutions`)
          sendEvent("changeSolutionsRoute", 3);
        }
        //案例中心
        //3.1
        else if(tagNumber === '3.1'){
          // let discard = false;
          // let index =1;
          // store.commit('changeNewsRouteRoute', {discard, index });//更新数据

          router.push(`/projectCases`)
          // sendEvent("changeNewsRouteRoute", 1);
        }
        //add 2022.7.28
        //创美优势
        //4.1
        else if(tagNumber === '4.1'){
          // let discard = false;
          // let index =1;
          // store.commit('changeNewsRouteRoute', {discard, index });//更新数据

          router.push(`/advantages`)
          // sendEvent("changeNewsRouteRoute", 1);
        }

        //新闻资讯
        //5.1
        else if(tagNumber === '5.1'){
          let discard = false;
          let index =1;
          store.commit('changeNewsRouteRoute', {discard, index });//更新数据

          router.push(`/news`)
          sendEvent("changeNewsRouteRoute", 1);
        }
        //5.2
        else if(tagNumber === '5.2'){
          let discard = false;
          let index =2;
          store.commit('changeNewsRouteRoute', {discard, index });//更新数据

          router.push(`/news`)
          sendEvent("changeNewsRouteRoute", 2);
        }

        //关于我们
        //6.1
        else if(tagNumber === '6.1'){
          let discard = false;
          let index =1;
          store.commit('changeAboutusRoute', {discard, index });//更新数据

          router.push(`/aboutUs`)
          sendEvent("changeAboutusRoute", 1);
        }
        //6.2
        else if(tagNumber === '6.2'){
          let discard = false;
          let index =2;
          store.commit('changeAboutusRoute', {discard, index });//更新数据

          router.push(`/aboutUs`)
          sendEvent("changeAboutusRoute", 2);
        }
        //6.3
        else if(tagNumber === '6.3'){
          let discard = false;
          let index =3;
          store.commit('changeAboutusRoute', {discard, index });//更新数据

          router.push(`/aboutUs`)
          sendEvent("changeAboutusRoute", 3);
        }
        //6.4
        else if(tagNumber === '6.4'){
          let discard = false;
          let index =4;
          store.commit('changeAboutusRoute', {discard, index });//更新数据

          router.push(`/aboutUs`)
          sendEvent("changeAboutusRoute", 4);
        }
        //6.5
        else if(tagNumber === '6.5'){
          let discard = false;
          let index =5;
          store.commit('changeAboutusRoute', {discard, index });//更新数据

          router.push(`/aboutUs`)
          sendEvent("changeAboutusRoute", 5);
        }
        //6.6
        else if(tagNumber === '6.6'){
          let discard = false;
          let index =6;
          store.commit('changeAboutusRoute', {discard, index });//更新数据

          router.push(`/aboutUs`)
          sendEvent("changeAboutusRoute", 6);
        }



      }

      //tab鼠标上移函数定义
      const handleTabMouseOver = (tagNumber) => {
        tagNum.value = tagNumber;
      }

      //获取上下文
      const ctx = useContext();
      const backTopClick = () => {
        //console.log("backTopClick=======》 ")

        ctx.emit('backTop');
      }

      //显示留言板
      const showMsgDialog = () => {
        //console.log("showMsgDialog=======》 ")

        ctx.emit('showMsgDialog');
      }

      return { handleTabClick, handleTabMouseOver, tagNum, backTopClick, showMsgDialog }

    }

  }
</script>

<style lang="scss" scoped>
  @import '../style/viriables.scss';

  .rootView{
    width: 100%;
    height: 2.78rem;

    /*隐藏滚动条*/
    overflow-x: hidden;
    overflow-y: auto;

    /*margin-top: 0.61rem; 如果放在 新闻资讯 后面，是这个距离*/
    margin-top: 0.8rem;

    /*背景图片设置*/
    /* background-image: url('../assets/common/home_bg@2x.jpg'); !*2021.8.10,改为使用vueload懒加载,不好使*!
    background-size: cover;
    background-position: center;*/

    position: relative;
    background-color: #4d4d4d;
    border-radius: 0;


    &_left{
      height: 1.18rem;   /*66 +40 +12*/
      width: auto;

      position: absolute;
      top: 0.85rem;
      left: 3.11rem;

      display: flex;
      flex-direction: column;
      /*flex: 1;*/

      &_bottom{
        width: 100%;
        height: 0.12rem;
        /*margin-top: 0.3rem;*/

        display: flex;
        flex-direction: row;

        position: absolute;
        left: 7.88rem;
        top: 2.26rem;

        z-index: 100;
      }

      &_bottom2{
        width: 100%;
        height: 0.12rem;
        /*margin-top: 0.3rem;*/

        display: flex;
        flex-direction: row;

        position: absolute;
        left: 7.88rem;
        top: 2.46rem;

        z-index: 100;
      }

    }


    &_center{
      width: 9.8625rem;  /*789 +197.25 */
      height: 2.10rem;  /*18 +20 +172 */

      position: absolute;
      top: 0.40rem;
      left: 7.89rem;

      display: flex;
      flex-direction: row;


      &_item{
        width: 1.5rem;
        height: 100%;

        display: flex;
        flex-direction: column;

        &_title{
          width: auto;
          height: 0.18rem;
          font-size: 0.18rem;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #FFFFFF;

          margin-top: 0rem;
          margin-left: 0rem;
          /*margin-bottom: 0.20rem;*/
        }

        &_tag{
          width: auto;
          height: 0.14rem;
          font-size: 0.14rem;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #FFFFFF;
          opacity: 0.8;

          margin-top: 0.20rem;
          margin-left: 0rem;
        }

      }

    }

  }

  /*选中设备类型后的，按钮激活效果*/
  .active{
    color: $colorPrimary;
  }


  /*add 2021.8.16 废除默认的上边距，首页新闻资讯需要*/
  .marginOff{
    margin-top: 0rem;
  }
</style>
