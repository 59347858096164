import axios from 'axios'

/** 网络请求封装 */

/*

//安装： npm install axios --save

//官网： https://github.com/axios/axios  (GitHub搜索axios)

//标准post请求：
  axios.post('/user', {
      //请求参数
      firstName: 'Fred',
      lastName: 'Flintstone'
    })
    .then(function (response) {
      //请求成功
      console.log(response);
    })
    .catch(function (error) {
      //请求失败
      console.log(error);
  });

*/

//服务器地址
// const ServerUrl = 'http://192.168.0.230:8100'; //内网

//const ServerUrl = 'http://localhost:8100'; //京东云 无效
//const ServerUrl = 'http://10.0.0.3:8100'; //京东云 无效
const ServerUrl = 'http://114.67.113.171:8100';//京东云 有效

//是否开发调试使用
const isDeBug = false;

const instance = axios.create({
  //baseURL: 'https://www.fastmock.site/mock/ae8e9031947a302fed5f92425995aa19/jd',
  baseURL: ServerUrl,
  timeout: 10000,
  //headers: {'X-Custom-Header': 'foobar'}
  crossdomain: true
})

export const get = (url, params = {}) => {
  if(isDeBug)
    console.log("发送请求：url=="+url + " ,请求参数=="+JSON.stringify(params));//todo 1、将对象转换为JSON格式字符串 JSON.stringify(object)  <--> 2、将JSON字符串转换为对象 JSON.parse(jsonString);


  return new Promise((resolve, reject) => {
    instance.get(url, { params }).then((response) => {
      resolve(response.data)


      if(isDeBug)
        console.log("请求返回："+JSON.stringify(response.data));//todo 1、将对象转换为JSON格式字符串 JSON.stringify(object)  <--> 2、将JSON字符串转换为对象 JSON.parse(jsonString);

    }, err => {
      reject(err)
    })
  })

}

/* 抽出baseURL的写法*/
export const post = (url, data = {}) => {

  if(isDeBug)
    console.log("发送请求：url=="+url + " ,请求参数=="+JSON.stringify(data));//todo 1、将对象转换为JSON格式字符串 JSON.stringify(object)  <--> 2、将JSON字符串转换为对象 JSON.parse(jsonString);

  return new Promise((resolve, reject) => {
    instance.post(url, data, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      resolve(response.data) //成功

      if(isDeBug)
        console.log("请求返回："+JSON.stringify(response.data));//todo 1、将对象转换为JSON格式字符串 JSON.stringify(object)  <--> 2、将JSON字符串转换为对象 JSON.parse(jsonString);
    }, err => {
      reject(err) //失败
    })
  })

}

/* 带baseURL的写法*/
export const postBaseUrl = (baseUrl, url ,data = {}) => {

  if(isDeBug)
    console.log("发送请求：url=="+baseUrl+url + " ,请求参数=="+JSON.stringify(data));//todo 1、将对象转换为JSON格式字符串 JSON.stringify(object)  <--> 2、将JSON字符串转换为对象 JSON.parse(jsonString);

  return new Promise((resolve, reject) => {
    axios.post(url, data, {
      baseURL: baseUrl,
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      resolve(response.data) //成功
      //resolve(response) //成功 不解构data了  一般只带data就行，业务代码会简单一些


      if(isDeBug)
        console.log("请求返回："+JSON.stringify(response.data));//todo 1、将对象转换为JSON格式字符串 JSON.stringify(object)  <--> 2、将JSON字符串转换为对象 JSON.parse(jsonString);

    }, err => {
      reject(err) //失败
    })
  })
}

export const patch = (url, data = {}) => {
  return new Promise((resolve, reject) => {
    instance.patch(url, data, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(response => {
        resolve(response.data)
      }).catch(err => {
      reject(err)
    })
  })
}
