
/** 回到顶部逻辑 Effect*/

const backToTopEffect = () => {

  //距离顶部，滑动的高度
  let scorllHigh = 0;
  let curDom = null;

  //添加监听
  const addLisener = (scrollDom) => {
    if (scrollDom.length) {
      curDom = scrollDom[0];
      // 开启滚动监听
      curDom.addEventListener('scroll', scrollToTop);

    }
  };

  const removeLisener = () => {
    curDom.removeEventListener('scroll', scrollToTop);//无效
    curDom = null;

  };


  //计算距离顶部的高度
  const scrollToTop = () => {
    scorllHigh = curDom.scrollTop; // 滚动条高度
    //console.log("scrollToTop===> scorllHigh=="+scorllHigh)

  };

  // 点击图片回到顶部方法，加计时器是为了过渡顺滑
  const backTop  = () => {
    //console.log("backTop===> scorllHigh=="+scorllHigh)

    if(curDom != null){
      //curDom.scroll(0, 0);

      //平滑移动
      let scorllHighTemp = scorllHigh;
      let speed = 5;//每次5个像素
      let timer = setInterval(() => {

        scorllHighTemp = scorllHighTemp - speed;
        if(scorllHighTemp < 0) {
          scorllHighTemp = 0
        }

        curDom.scroll(0, scorllHighTemp);

        if (scorllHighTemp === 0) {
          console.log("backTop===> scorllHigh=="+scorllHighTemp)
          clearInterval(timer)
        }

      }, 10)

    }

  };


  return { addLisener, removeLisener, backTop }

};

export default backToTopEffect



/*
  使用：
  import useCommonAddressEffect from '../../effects/addressEffect'

  const { getAddressList } = useCommonAddressEffect()
  getAddressList()
*/
