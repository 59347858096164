<template>

  <div class="rootView" >

    <!--  关闭图标    -->
    <el-image
      :src="require('../assets/message-board/close.png')"
      fit="cover"
      @click="closeMsgDialog"
      style="
        width: 0.2rem;
        height: 0.2rem;

        position:absolute;
        top: 0.21rem;
        right: 0.2rem;

      "
      lazy
    />

    <text v-if="isSubmit==false"
      style="
        width: 0.8rem;
        height: 0.2rem;
        font-size: 0.2rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        line-height: 0.32rem;

        position:absolute;
        top: 0.20rem;
        left: 1.20rem;

      ">
      请您留言
    </text>

    <view v-if="isSubmit==false" class="contentView">

      <el-input
        style="
          width: 2.6rem;
          height: 1rem;
          border-radius: 0.04rem;
        "
        type="textarea"
        show-word-limit
        :rows="4.5"
        maxlength="100"
        placeholder="请输入留言内容，我们会尽快与您联系（必填）"
        @click="clickItem(0)"
        v-model="messageBoardObjRelative.content">
      </el-input>

      <!--姓名-->
      <el-input
        class="my-el-input"
        style="
          width: 2.6rem;
          height: 0.5rem;
          border-radius: 0.04rem;
          margin-top: 0.08rem;

        "
        placeholder="请输入姓名"
        maxlength="20"
        @click="clickItem(1)"
        v-model="messageBoardObjRelative.name"
        ref="nameBox">

          <template #prefix>
            <el-image
              :src="require('../assets/message-board/'+ nameIcon)"
              fit="cover"
              style="
                  width: 0.15rem;
                  height: 0.15rem;
                  margin-left: 0.10rem;
                  margin-top: 0.12rem;
              "
            />
          </template>

      </el-input>

      <!--电话-->
      <el-input
        style="
          width: 2.6rem;
          height: 0.5rem;
          border-radius: 0.04rem;
        "
        placeholder="请输入手机号（必填）"
        type="number"
        maxlength="11"
        @click="clickItem(2)"
        v-model="messageBoardObjRelative.phone">

        <template #prefix>
          <el-image
            :src="require('../assets/message-board/'+ phoneIcon)"
            fit="cover"
            style="
                  width: 0.15rem;
                  height: 0.15rem;
                  margin-left: 0.10rem;
                  margin-top: 0.12rem;
              "
          />
        </template>

      </el-input>

      <!--邮箱-->
      <el-input
        style="
          width: 2.6rem;
          height: 0.5rem;
          border-radius: 0.04rem;
        "
        placeholder="请输入邮箱"
        maxlength="50"
        @click="clickItem(3)"
        v-model="messageBoardObjRelative.email">

        <template #prefix>
          <el-image
            :src="require('../assets/message-board/'+ emailIcon)"
            fit="cover"
            style="
                  width: 0.15rem;
                  height: 0.15rem;
                  margin-left: 0.10rem;
                  margin-top: 0.12rem;
              "
          />
        </template>

      </el-input>

      <!--地址-->
      <el-input
        style="
          width: 2.6rem;
          height: 0.5rem;
          border-radius: 0.04rem;
        "
        maxlength="50"
        placeholder="请输入地址"
        @click="clickItem(4)"
        v-model="messageBoardObjRelative.address">

        <template #prefix>
          <el-image
            :src="require('../assets/message-board/'+ placeIcon)"
            fit="cover"
            style="
                  width: 0.15rem;
                  height: 0.15rem;
                  margin-left: 0.10rem;
                  margin-top: 0.12rem;
              "
          />
        </template>

      </el-input>

      <!--提交按钮-->
      <el-button
        type="primary"
        @click="handleSubmitClick()"
        style="
          width: 2.6rem;
          height: auto;
          border-radius: 0.04rem;
          margin-top: 0.1rem;
        "
      >提交</el-button>


    </view>

    <view v-if="isSubmit==true" class="successView">
      <el-image
        :src="require('../../public/logo.png')"
        fit="cover"
        style="
              width: 0.88rem;
              height: 0.88rem;
            "
        lazy
      />
      <text
        style="
              width: 3.2rem;
              height: auto;
              text-align: center;

              font-size: 0.14rem;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #000000;

              margin-top: 0.6rem;

            ">
        感谢您的留言，我们会尽快联系您~
      </text>


      <!--关闭按钮-->
      <el-button
        type="primary"
        @click="closeMsgDialog"
        style="
          width: 2.6rem;
          height: auto;
          border-radius: 0.04rem;
          margin-top: 0.7rem;
        "
      >关闭</el-button>


    </view>

  </div>
</template>

<script>
  //import { useRouter } from 'vue-router'// CompositionAPI 中使用 Vue-Router
  import {onMounted, ref} from 'vue'
  import { useContext } from 'vue'

  // import { toRefs } from "vue";
  //import {relative} from "@vue/compiler-sfc/dist/compiler-sfc.esm-browser";
  // CompositionAPI 中使用 VueX
  import { reactive } from 'vue'
  //import { useStore } from 'vuex'

  import {ElNotification} from 'element-plus';


  import { post } from '../utils/request'
  import {isEmpty, parseStr, parseStrValidLength} from '../utils/stringUtil'
  import {isEmail, isPhone} from "../utils/validateUtil";

  //消息提示
  const showNotification = (msg) => {
    ElNotification({
      title: '系统提示',
      message: msg,
      position: 'bottom-right',
    });
  };


  //提交逻辑封装
  const submitEffect = (messageBoardObjRelative) => {

    const handleSubmit = async (isSubmitSet) => {

      try {
        console.log("messageBoardObjRelative== "+messageBoardObjRelative);

        if(isEmpty(messageBoardObjRelative.phone))
        {
          showNotification("手机号为必填项，请您填写完再提交");
          return;
        }

        if(parseStrValidLength(messageBoardObjRelative.phone) !== 11)
        {
          showNotification("请输入正确的手机号");
          return;
        }

        //const mobile_mode = /^1[34578]\d{9}$/;
        //if(!mobile_mode.test(messageBoardObjRelative.phone)){
        //  showNotification("请输入正确的手机号");
        //  return;
        //}
        if(!isPhone(messageBoardObjRelative.phone))
        {
          showNotification("请输入正确的手机号");
          return;
        }

        if(!isEmpty(messageBoardObjRelative.email))
        {
          if(!isEmail(messageBoardObjRelative.email))
          {
            showNotification("请输入正确的邮箱");
            return;
          }
        }

        const result = await post('/base/online-consult/save', {
          "address": parseStr(messageBoardObjRelative.address),
          "content": parseStr(messageBoardObjRelative.content),
          "email":  parseStr(messageBoardObjRelative.email),
          "phone":  parseStr(messageBoardObjRelative.phone),
          "userName":  parseStr(messageBoardObjRelative.name)
        })

        //console.log("result== "+ JSON.stringify(result));//todo 1、将对象转换为JSON格式字符串 JSON.stringify(object)  <--> 2、将JSON字符串转换为对象 JSON.parse(jsonString);

        if (result?.code === 1) {      //OC简洁语法！！！
          showNotification('提交成功')

          //messageBoardObjRelative = {};
          isSubmitSet(true);

        } else {
          showNotification('提交失败')

        }

      } catch (e) {
        showNotification('提交失败')


      }


    }

    return { handleSubmit }
  }


  export default {
    name: "MessageBoard",

    setup() {

      //vue 3 动态修改样式
      let nameBox = ref(null);

      onMounted(() => {
        //console.log('onMounted-----------------------------------MessageBoard.vue')

        // 回到顶部 滚动条所在节点，添加监听
        //console.log(nameBox.value)
        //console.log(nameBox.value.target.input)


      });


      //获取上下文
      const ctx = useContext();

      //显示留言板
      const closeMsgDialog = () => {
        //console.log("closeMsgDialog=======》 ")

        ctx.emit('closeMsgDialog');

        isSubmitSet(false);
      }

      const messageBoardObj = {
        content: '',
        name: '',
        phone: '',
        email: '',
        address: ''
      }


      const messageBoardObjRelative = reactive(messageBoardObj);


      //图标切换
      const nameIcon = ref('icon_user.png');
      const phoneIcon = ref('icon_phone.png');
      const emailIcon = ref('icon_emil.png');
      const placeIcon = ref('icon_place.png');

      const clickItem = (index) => {
        //console.log("clickItem=======》 ")

        nameIcon.value = 'icon_user.png';
        phoneIcon.value = 'icon_phone.png';
        emailIcon.value = 'icon_emil.png';
        placeIcon.value = 'icon_place.png';


        if(index == 1)
        {
          nameIcon.value = 'icon_user2.png';
        }
        else if(index == 2)
        {
          phoneIcon.value = 'icon_phone2.png';
        }
        else if(index == 3)
        {
          emailIcon.value = 'icon_emil2.png';
        }
        else if(index == 4)
        {
          placeIcon.value = 'icon_place2.png';
        }


      }



      //提交
      // 引入登录相关的逻辑和数据
      const { handleSubmit } = submitEffect(messageBoardObjRelative)

      const handleSubmitClick = () => {

        //其他填写框，图标置灰
        nameIcon.value = 'icon_user.png';
        phoneIcon.value = 'icon_phone.png';
        emailIcon.value = 'icon_emil.png';
        placeIcon.value = 'icon_place.png';


        handleSubmit(isSubmitSet);
      }


      const isSubmit = ref(false);
      const isSubmitSet= (val) => {
        isSubmit.value = val;
      }

      return { handleSubmitClick,  closeMsgDialog, messageBoardObjRelative,
        nameIcon, phoneIcon, emailIcon, placeIcon, clickItem,
        nameBox, isSubmit, isSubmitSet}

    }

  }


</script>

<style lang="scss">
  @import '../style/viriables.scss';

  .rootView {
    width: 3.20rem;
    height: 4.89rem;

    position: relative;
    background: white;
    border-radius: 0.1rem;

    overflow-y: auto;

  }

  .contentView{
    width: 100%;
    height: 3.98rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    position: absolute;
    left: 0;
    top: 0.61rem;

  }
  .successView{
    width: 100%;
    height: 3.98rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    position: absolute;
    left: 0;
    top: 0.61rem;
  }


  /*选中设备类型后的，按钮激活效果*/
  .active{
    color: $colorPrimary;

  }


  /*输入框文本偏移量  todo： 不生效，去掉style上的scoped，变为全局样式*/
  .el-input--prefix .el-input__inner {
    padding-left: 40px;
  }






</style>
