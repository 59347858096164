
/** 手机号、邮箱等校验工具类封装 */

/*
export,import和export default的关系
export 与import是es6中新增模块功能主要的两个命令。

1.export与export default均可用于导出常量、函数、文件、模块等
2.在一个文件或模块中，export、import可以有多个，export default仅有一个
3.通过export方式导出，在导入时要加{ }，export default则不需要{ }

————————————————
版权声明：本文为CSDN博主「~疆」的原创文章，遵循CC 4.0 BY-SA版权协议，转载请附上原文出处链接及本声明。
原文链接：https://blog.csdn.net/qq_40323256/article/details/101867706
 */





/* 是否手机号码*/
export function isPhone(value) {

  if (value == '' || value == undefined || value == null) {
    return false;
  }

  value = value.trim();

  const reg = /^1[3456789]\d{9}$/;

  if ((!reg.test(value))) {
    return false;
  }

  return true;

}


/* 是否邮箱*/
export function isEmail(value) {

  if (value == '' || value == undefined || value == null) {
    return false;
  }

  value = value.trim();

  var regEmail = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
  if (!regEmail.test(value))
  {
    return false;
  }

  return true;

}

