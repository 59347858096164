
/** 字符串工具类封装 */


export const isEmpty = (s) => {
  if(s === null)
    return true;

  if(s === undefined)
    return true;

  if(s === '')
    return true;

  if(s.trim().length === 0)
    return true;

  return false;


}


export const parseStr = (s) => {
  if(s === null)
    return "";

  if(s === undefined)
    return "";

  if(s === '')
    return "";

  if(s.trim().length === 0)
    return "";

  return s.trim();


}


/*去除空格后的 有效长度*/
export const parseStrValidLength = (s) => {
  if(s === null)
    return 0;

  if(s === undefined)
    return 0;

  if(s === '')
    return 0;

  return s.trim().length;

}



